import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ArrowBack } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { DIM_FALLBACK_ERROR_MESSAGE, DIMError } from '../../../constants/federalRepayments/dimErrors';
import { DIM_ERROR_EVENT_MESSAGES, DIM_ERROR_EVENTS, DIMEvent } from '../../../constants/federalRepayments/dimEvents';
import useAlert from '../../../hooks/useAlert';
import { addDIMEventOrErrorMetadataToLogRocketSession } from '../../../lib/federalRepayments/dimUtils';
import spinwheelActiveModal from '../../../state/atoms/spinwheelActiveModal';
import spinwheelSessionToken from '../../../state/atoms/spinwheelSessionToken';
import { useUser } from '../../../state/user';
import { DIM_CONTAINER_IDS, DIM_NAMES, REPAYMENT_MODAL_STEPS } from '../../../types/spinwheelModals';
import ExternalWidgetModal from '../../dataDisplay/ExternalWidgetModal';

const SpinwheelListTransactionsModal: FunctionComponent = () => {
  const user = useUser();
  const { showErrorAlert } = useAlert();
  const setActiveModal = useSetRecoilState(spinwheelActiveModal);
  const [ isComponentMounted, setIsComponentMounted ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const sessionToken = useRecoilValue(spinwheelSessionToken);

  /**
   * DIM Callbacks definition
   */

  /**
   * Callback invoked when the Spinwheel DIM operation is successfully completed.
   */
  const onSuccess = useCallback(() => {}, []);

  /**
   * Callback when the DIM is loaded.
   */
  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  /**
   * Callback for when the user exits the DIM.
   */
  const onExit = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  /**
   * Handles events and error events emitted by the Spinwheel DIM.
   */
  const onEvent = useCallback((eventPayload: DIMEvent) => {
    console.debug(`${DIM_CONTAINER_IDS.SPINWHEEL_LIST_TRANSACTIONS} - Event`, eventPayload);

    // If the event name corresponds to a dim error event, then handle the event as an error event
    if (eventPayload?.eventName in DIM_ERROR_EVENTS) {
      showErrorAlert(DIM_ERROR_EVENT_MESSAGES?.[eventPayload.eventName as DIM_ERROR_EVENTS] ?? DIM_FALLBACK_ERROR_MESSAGE);
      addDIMEventOrErrorMetadataToLogRocketSession(eventPayload);

      return ;
    }

    // Otherwise handle the event normally
  }, [showErrorAlert]);

  /**
   * Callback for handling errors within the DIM.
   * Logs error details for troubleshooting.
   */
  const onError = useCallback((errorPayload: DIMError) => {
    console.debug(`${DIM_CONTAINER_IDS.SPINWHEEL_LIST_TRANSACTIONS} - Error`, errorPayload);
    showErrorAlert(DIM_FALLBACK_ERROR_MESSAGE);
    addDIMEventOrErrorMetadataToLogRocketSession(errorPayload);
    setIsLoading(false);
    // TODO add handler logic on fail, SSP-2138
  }, [showErrorAlert]);

  /**
   * Callback for resize events in the DIM.
   */
  const onResize = useCallback(() => {}, []);

  useEffect(() => setIsComponentMounted(true), []);

  // Effect for initializing Spinwheel DIM API
  useEffect(() => {
    if (!user.isLoaded) return console.debug('Waiting for user to be loaded');
    if (!sessionToken) return console.error('DIM could not be loaded, no session token provided');
    if (!isComponentMounted) return console.debug('Waiting for container DOM initialization');
    // Spinwheel instance is from their script
    // @ts-ignore
    const SpinwheelApi = Spinwheel;

    // Spinwheel script initialized
    const handler = SpinwheelApi.create({
      containerId: DIM_CONTAINER_IDS.SPINWHEEL_LIST_TRANSACTIONS,
      dropinConfig: {
        module: DIM_NAMES.TRANSACTION_HISTORY,
        token: sessionToken,
      },
      onSuccess,
      onLoad,
      onExit,
      onEvent,
      onError,
      onResize,
    });

    handler.open();
  }, [ isComponentMounted, onError, onEvent, onExit, onLoad, onResize, onSuccess, setActiveModal, sessionToken, user.isLoaded ]);

  return (
    <ExternalWidgetModal height={620} isLoading={isLoading} width={400}>
      <Box display='flex' justifyContent='start' p={2}>
        <Button
          onClick={() => setActiveModal(REPAYMENT_MODAL_STEPS.LOAN_LIST)}
          startIcon={<ArrowBack />}
          variant='contained'
        >
          Back to Loans
        </Button>
      </Box>
      <div id={DIM_CONTAINER_IDS.SPINWHEEL_LIST_TRANSACTIONS} style={{ height: '100%' }} />
    </ExternalWidgetModal>
  );
};

export default SpinwheelListTransactionsModal;
