import { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button, Dialog, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField, Typography  } from '@mui/material';

import useAlert from '../../hooks/useAlert';
import backend from '../../lib/backend';
import displayUnsupportedServicerModal from '../../state/atoms/displayUnsupportedServicerModal';

const UnsupportedServicer: FunctionComponent = () => {
  const [ isModalOpen, setIsModalOpen ] = useRecoilState(displayUnsupportedServicerModal);
  const [ servicerName, setServicerName ]  = useState<string | null>(null);
  const [ isFederalLoan, setIsFederalLoan ] = useState<string | null>(null);
  const [ submitDisabled, setSubmitDisabled ] = useState<boolean>(true);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    servicerName && isFederalLoan ? setSubmitDisabled(false) : setSubmitDisabled(true);
  }, [ servicerName, isFederalLoan ]);

  const handleSubmit = async () => {
    if (servicerName && isFederalLoan){
      await backend.submitUnsupportedServicerDetails({ servicerName, isFederalLoan })
        .then(() => {
          showSuccessAlert('Thank you for submitting your servicer details. Someone will be in touch with you shortly!');
          setIsModalOpen(false);
        })
        .catch((error) => {
          showErrorAlert('We encoutered an error submitting your servicer details. Please try again later or contact Stride support for more help.');
          console.error(error);
        });
    }
  };

  return (
    <>
      <Dialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <Grid alignItems='center' container direction='column' justifyContent='center' margin='auto' spacing={2} width='100%'>
          <Grid item xs={12}>
            <Typography mb={3} sx={{ textAlign: 'left' }} variant='h4'>
              Please provide a few more details. One of our reps will contact you shortly with next steps
            </Typography>
          </Grid>
          <Grid item mb={3} xs={12}>
            <TextField
              defaultValue={servicerName}
              id='servicer-name'
              label='Servicer Name'
              onChange={
                (e) => {
                  setServicerName(e.target.value);
                }
              }
              required
              type='text'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component='legend'>Is this a Federal Student Loan?</FormLabel>
            <FormGroup>
              <RadioGroup
                onChange={
                  (e) => {
                    setIsFederalLoan(e.target.value);
                  }
                }
                row
                value={isFederalLoan}
              >
                <FormControlLabel control={<Radio required />} label='Yes' value='yes' />
                <FormControlLabel control={<Radio required />} label='No' value='no' />
                <FormControlLabel control={<Radio required />} label='Unsure' value='unsure' />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Button
            disabled={submitDisabled} onClick={
              () => {
                handleSubmit();
              }
            } sx={{ mt: 3, mb: 3 }} variant='contained'
          >Submit
          </Button>
        </Grid>
      </Dialog>
    </>
  );
};

export default UnsupportedServicer;
