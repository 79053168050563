export enum BorrowerAccountProfileConnectionStatus {
    CONNECTED = 'CONNECTED',
    IN_PROGRESS = 'IN_PROGRESS',
    DISCONNECTED = 'DISCONNECTED',
}

export interface BorrowerLoanDetails {
    outstanding_balance: string;
    interest_rate: string;
    outstanding_loan_term: string;
    reported_on: string ;
}

export interface BorrowerAccountProfile {
    id: string;
    customer_repayment_product: string;
    account_number: string;
    account_number_verified_on: string;
    ext_id: string;
    repayment_eligible: boolean;
    origination_amount: string;
    origination_date: string;
    full_loan_term: string;
    servicer_name: string;
    servicer_display_name: string;
    account_details: BorrowerLoanDetails;
    connection_status?: BorrowerAccountProfileConnectionStatus;
}

