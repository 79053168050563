export type ConfirmationMessages = {
    title: string;
    description: string;
    confirm: string;
    cancel: string;
};

export type ConfirmationStatus = {
    dialogOpen: boolean;
    onClick: () => void;
    messages?: Partial<ConfirmationMessages>;
};

export const DEFAULT_CONFIRMATION_MESSAGES: ConfirmationMessages = {
  title: 'Are you sure?',
  description: 'This action cannot be undone.',
  confirm: 'Confirm',
  cancel: 'Cancel',
};
