import { SponsorshipApplicationFiles } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const submitFiles = async (sponsorshipApplicationId: string, files: FormData): Promise<AxiosResponse<SponsorshipApplicationFiles>> => {
  return backendAuthenticatedAxios.post(`/esl-student/${sponsorshipApplicationId}/submit-files`, files);
};

export default submitFiles;
