import Form, { Field } from '../../../lib/recoil/Form';
import { UsState } from '../../../types/eligibility';

const commonApplicationProfileForm = new Form({
  fields: {
    major: new Field<string>({
      key: 'major',
      defaultValue: '',
      rules: [
        {
          helperText: 'Major is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    expectedGraduationDate: new Field<string>({
      key: 'expectedGraduationDate',
      defaultValue: '',
      rules: [
        {
          helperText: 'Expected graduation date must be in M/D/YYYY format!',
          pattern: (value) => /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/.test(value),
          severity: 'error',
        },
      ],
    }),
    institution: new Field<string>({
      key: 'institutionName',
      defaultValue: '',
      rules: [
        {
          helperText: 'Institution name is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    degreeType: new Field<string | null>({
      key: 'degreeType',
      defaultValue: null,
      rules: [
        {
          helperText: 'Degree type is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    current_gpa: new Field<string | null>({
      key: 'commonAppGpa',
      defaultValue: null,
      rules: [
        {
          helperText: 'GPA should be a positive number up to three digits with an optional decimal point followed by more digits',
          pattern: (value) => typeof value === 'number' || (typeof value === 'string' && /^\d{0,3}(\.\d*)?$/.test(value) && parseFloat(value) >= 0),
          severity: 'error',
        },
      ],
    }),
    // Mailing address optional fields
    address_line: new Field<string>({
      key: 'commonAppAddressLine',
      defaultValue: '',
      persists: false,
      rules: [],
    }),
    address_detail: new Field<string>({
      key: 'commonAppAddressDetail',
      defaultValue: '',
      persists: false,
      rules: [],
    }),
    city: new Field<string>({
      key: 'commonAppCity',
      defaultValue: '',
      persists: false,
      rules: [],
    }),
    state: new Field<UsState | null>({
      key: 'commonAppState',
      defaultValue: null,
      persists: false,
      rules: [],
    }),
    zip: new Field<string>({
      key: 'commonAppZip',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: 'Zip code must be 5 or 9 digits (hyphen or space).',
          pattern: (value) => !value || /^\d{5}(?:[-\s]\d{4})?$/.test(value),
          severity: 'error',
        },
      ],
    }),
  },
});

export default commonApplicationProfileForm;
