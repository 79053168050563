
import { CommonApplicationProfile } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '..';

import { UpdateMailingAddressInfo } from './updateMailingAddressInfo';

import { AxiosResponse } from 'axios';

export interface UpdateCommonAppData extends Partial<UpdateMailingAddressInfo>{
    institution_name: string | null;
    expected_graduation_date: string;
    major: string;
    gpa: string | null;
    degree_type: string | null;
  }

const updateCommonApplicationProfile = async (common_app_data: UpdateCommonAppData): Promise<AxiosResponse<CommonApplicationProfile | null>> => {
  /* Backend request to update the CommonApplicationProfile. */

  return await backendAuthenticatedAxios.patch<CommonApplicationProfile>('/common-application-profile/update-common-application-profile', common_app_data);
};

export default updateCommonApplicationProfile;
