import { atom } from 'recoil';

import { REPAYMENT_MODAL_STEPS } from '../../types/spinwheelModals';

// Recoil state to determine the active Spinwheel modal
const spinwheelActiveModal = atom<REPAYMENT_MODAL_STEPS | null>({
  key: 'spinwheelActiveModal',
  default: null,
});

export default spinwheelActiveModal;
