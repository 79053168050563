/**
 * Constants required for displaying text on the ExternalLinkModal.
 * To add a new custom exit message for a new destination-
 *  1. Add destination to ExternalLinkModalDestination
 *  2. Add modal text to externalLinkModalText
 */

import routes from '../lib/routes';
import { ExternalRoute } from '../lib/routes/types';

// All possible exit destinations in the portal
type ExternalLinkModalDestination = 'launchservicing' | 'knowledgefinance' | 'campusdoor' | 'default';

// External Link copy data structure
export type ExternalLinkModalText = {
    title: string;
    body: string;
    onContinueText?: string;
    onCancelText?: string;
  };

// All external link texts, per destination
const externalLinkModalText: Record<ExternalLinkModalDestination, ExternalLinkModalText> = {
  launchservicing: {
    title: 'You are leaving Stride Funding',
    body: 'For all repayment related information, we\'re directing you to Launch Servicing, Stride Funding’s servicing partner.',
  },
  knowledgefinance: {
    title: 'You are leaving Stride Funding',
    body: 'For all repayment related information, we\'re directing you to Knowledge Finance, Stride Funding’s servicing partner.',
  },
  campusdoor: {
    title: 'We’re taking you to your application',
    body: 'This should take about 10 minutes to complete with our application partner Campus Door on their website.',
  },
  default: {
    title: 'You are leaving Stride Funding',
    body: 'You are leaving the Stride Funding, Inc. website and are entering the website of a non-affiliated third party. Stride Funding, Inc. is not responsible for the content of this site, and the Stride Funding, Inc. privacy policy and security practices do not apply to this site.',
  },
};

// For any routes that will use a non-default modal text
// We need a base URL and the ExternalLinkModalText
export type NonDefaultExternalModalText = {
  baseUrl: string;
  modalText: ExternalLinkModalText;
};

const nonDefaultExternalModalText : NonDefaultExternalModalText[] = [
  {
    baseUrl: 'campusdoor.com',
    modalText: externalLinkModalText.campusdoor,
  },
  {
    baseUrl: 'knowledgefinance.com',
    modalText: externalLinkModalText.knowledgefinance,
  },
  {
    baseUrl: 'launchservicing.com',
    modalText: externalLinkModalText.launchservicing,
  },
];

// Helper function to check when to show external
// link modal and what text to show
// TODO-> SSP-1013 to make this logic much much better/scalable
const isRouteNonStride = (url?: string ) : [boolean, ExternalLinkModalText] => {
  // Instantialize default values for returning
  let isExternalLink = false;
  let modalText = externalLinkModalText.default;

  if (!url) return [ isExternalLink, modalText ];

  // Check if specific non-default external link and update return values if there's a match
  nonDefaultExternalModalText.some((routeInfo : NonDefaultExternalModalText) => {
    if (url.includes(routeInfo.baseUrl)){
      isExternalLink = true;
      modalText = routeInfo.modalText;
    }
  });

  if (!isExternalLink) {
    // If not external link, check if any (non-stride) other external link
    const strideMarketingWebsiteBase = 'stridefunding.com';

    Object.values(routes.external).some((externalRoute : ExternalRoute) => {
      if (externalRoute.url ===  url && !url.includes(strideMarketingWebsiteBase)){
        isExternalLink = true;
      }
    });
  }

  // Other wise return defaults
  return [ isExternalLink, modalText ];
};

export {
  externalLinkModalText,
  isRouteNonStride,
  nonDefaultExternalModalText,
};
