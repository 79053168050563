import { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Box, CircularProgress, Grid, SxProps, Typography } from '@mui/material';
import { captureException, setContext, withScope } from '@sentry/browser';

import { eventSteps } from '../../../constants/bankIntegrationData';
import backend from '../../../lib/backend';
import { FlinksData } from '../../../lib/backend/requests/handleBankEvent';
import BankAccountLinkError from '../../../lib/customExceptions/BankAccountLinkError';
import env from '../../../lib/env';
import routes from '../../../lib/routes/index';
import flinksModalUserState from '../../../state/atoms/flinksModalUserState';
import isBankConnectError from '../../../state/atoms/isBankConnectError';
import { useUser } from '../../../state/user';

type FlinksConnectWrapperProps =  {
  // Can be used to override any SX props being passes to the wrapper
  wrapperOverrideStyleSx?: SxProps;
  overrideIframeHeight?: string;
  overrideIframeWidth?: string;
};

const submitFlinksData = async (customerId: string, data: FlinksData) => {
  return await backend.handleBankEvent(customerId, data);
};

const FlinksConnectWrapper: FunctionComponent<FlinksConnectWrapperProps> = ({ overrideIframeHeight, overrideIframeWidth, wrapperOverrideStyleSx }) => {
  const iframeHeight = overrideIframeHeight ?? '540px';
  const iframeWidth = overrideIframeWidth ?? '375px';

  const [ loading, setLoading ] = useState(true);

  const [ flinksData, setFlinksData ] = useState<FlinksData>({
    'loginId': '',
    'institutionName': '',
  });

  const user = useUser();

  const [ currFlinksEvent, setCurrFlinksEvent ] = useRecoilState(flinksModalUserState);
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const [ customerId, setCustomerId ] = useState<string | null>(null);

  const [ bankConnectError, setBankConnectError ] = useRecoilState(isBankConnectError);

  // Flinks API search parameters
  const searchParams = new URLSearchParams();
  searchParams.append('demo', `${env.feature.FLINKS_DEMO_MODE ?? false}`);
  searchParams.append('termsUrl', `${routes.external.terms.url}`);
  searchParams.append('termsNoCheckbox', 'true');
  searchParams.append('customerName', 'Stride Funding');
  searchParams.append('consentEnable', 'true');
  searchParams.append('daysOfTransactions', 'Days365');

  const flinksUrl = `${env.var.FLINKS_URL_BASE}?${searchParams.toString()}`;

  // Flinks event handler
  // Can be moved to a standalone function
  // if the logic gets complicated
  const handleFlinksEvent = (event: MessageEvent) => {
    setCurrFlinksEvent(event.data);
  };

  useEffect(() => {
    // Add listener when component mounts
    window.addEventListener('message', handleFlinksEvent);

    // Remove listening when component unmounts
    // (will still capture events, just not create copies)
    return () => {
      window.removeEventListener('message', handleFlinksEvent );
    };
  // Allow useEffect with no deps to run this only once
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.state === 'hasValue' && user.contents) {
      // set state variable once user is loaded
      setCustomerId(user.contents.customerId);
    }
  }, [user]);

  useEffect(() => {
    if (currFlinksEvent?.step === eventSteps.componentLoadConsentIntro)
      setLoading(false);

    if (currFlinksEvent?.step === eventSteps.institutionSelected && currFlinksEvent)
      setFlinksData((f) => ({
        ...f,
        'institutionName': currFlinksEvent?.institution,
      }));

    if (currFlinksEvent?.step === eventSteps.redirect && currFlinksEvent)
      setFlinksData((f) => ({
        ...f,
        'loginId': currFlinksEvent?.loginId,
      }));
  }, [currFlinksEvent]);

  useEffect(() => {
    if (flinksData.loginId && customerId && !isSubmitted) {
      console.log('LoginId set for flinkData, submitting...');
      /**
       * @note: Ensure to flick the `setIsSubmitted` switch to avoid running the code again.
       * This useEffect keeps running due to the stream of event updates from flinks widget,
       * which causes more than 1 POST/PATCH to the BE.
       */
      setIsSubmitted(true);

      submitFlinksData(customerId, flinksData)
        .catch((err) => {
          console.error('Failed to record Banking information. \n' + err);
          setBankConnectError(true);

          /** @note Bubble up exception + bank data to sentry as a fallback */
          withScope(() => {
            setContext('Flinks-Customer', {
              id: customerId,
              loginId: flinksData.loginId,
              institutionName: flinksData.institutionName,
              flinksUrl: env.var.FLINKS_URL_BASE,
            });
            captureException(new BankAccountLinkError('Bank Account linking failed: ' + err));
          });
        }).finally(() => {
          user.refresh();
        });
    }
  }, [ flinksData, customerId, user, isSubmitted, setBankConnectError ]);

  return (
    <Grid
      borderRadius={1}
      boxSizing='border-box'
      p={1}
      sx={wrapperOverrideStyleSx ?? {}}
    >
      {
        loading && !bankConnectError && (
          <Box
            alignItems='center'
            display='flex'
            height={iframeHeight}
            justifyContent='center'
            pt={10}
            textAlign='center'
            width={iframeWidth}
          >
            <CircularProgress size={48} sx={{ margin: 0 }} />
          </Box>
        )
      }
      {
        !bankConnectError
          ? (
            <iframe
              height={loading ? 1 : iframeHeight}
              src={flinksUrl}
              style={{ 'border': '0px' }}
              width={iframeWidth}
            />
          )
          : (
            <Typography align='center' mt={5} variant='body1'>
              Something went wrong. If the problem persists, please contact <a href='mailto:support@stridefunding.com'>support@stridefunding.com</a>
            </Typography>
          )
      }
    </Grid>
  );
};

export default FlinksConnectWrapper;
