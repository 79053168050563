import { backendAuthenticatedAxios } from '..';

type SpinwheelUserDataResponse = {
  raw_data: JSON;
  liabilities: JSON[];
};

/**
 * Fetch customer liabilities from Spinwheel
 */
const fetchSpinwheelUserData = async ({
  customerId,
}: {
  customerId: string;
}): Promise<JSON[] | null> => {
  try {
    const response
      = await backendAuthenticatedAxios.post<SpinwheelUserDataResponse>(
        '/spinwheel/user/get-data',
        { customer_id: customerId },
      );

    return response.data.liabilities;
  } catch (err) {
    console.log('error getting user data', err);

    // return null, error handling downstream
    return null;
  }
};

export default fetchSpinwheelUserData;
