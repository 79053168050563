export enum REPAYMENT_MODAL_STEPS {
    LOAN_LIST = 'loan-list',
    LOAN_SERVICES_LOGIN = 'loan-servicers-login',
    KBA_VERIFICATION = 'kba-verification',
    SELECT_LOAN_SERVICER_ACCOUNT = 'select-loan-servicer-account',
    TRANSACTION_HISTORY = 'transaction-history',
    USER_AGREEMENT = 'user-agreement',
}

export enum ACCOUNT_NUMBER_MODALS {
    UPDATE = 'update',
    CONFIRM = 'confirm',
}

export enum DIM_NAMES {
    IDENTITY_CONNECT = 'identity-connect',
    KBA = 'debt-connect',
    LOAN_SERVICES_LOGIN = 'loan-servicers-login',
    TRANSACTION_HISTORY = 'transaction-history',
    LOAN_LIST = 'loan-list'
}

export enum DIM_CONTAINER_IDS {
    SPINWHEEL_IDENTITY_CONNECT = 'SPINWHEEL_IDENTITY_CONNECT_DIM_CONTAINER',
    SPINWHEEL_KBA = 'SPINWHEEL_KBA_DIM_CONTAINER',
    SPINWHEEL_DIRECT_LOAN_CONNECT = 'SPINWHEEL_DIRECT_LOAN_CONNECT_DIM_CONTAINER',
    SPINWHEEL_LIST_TRANSACTIONS = 'SPINWHEEL_LIST_TRANSACTIONS_DIM_CONTAINER',
    SPINWHEEL_LIST_LOANS = 'SPINWHEEL_LIST_LOANS_DIM_CONTAINER'
}
