import { backendAuthenticatedAxios } from '..';
import jwt, { Tokens } from '../auth/jwt';

interface CreateUserRequest {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    name?: string;
}

const createUser = async ({
  email,
  password,
  firstName,
  lastName,
  name,
  mobileNumber,
}: CreateUserRequest): Promise<void> => {
  return backendAuthenticatedAxios.post<Partial<Tokens>>(
    '/account/', {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      name: name || `${firstName} ${lastName}`,
      mobile_number: mobileNumber,
    },
  ).then(
    (res) => {
      if (res.status === 201) {
        const { access, refresh } = res.data;

        jwt.save(access, refresh);
      } else {
        throw new Error('Failed to create user');
      }
    },
  );
};

export default createUser;
