import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Stack, Typography } from '@mui/material';

import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import { sponsorshipAgreementActiveModal } from '../../../state/atoms/sponsorshipAgreementLoadingModalOpenState';
import mailAddressConfirmationForm from '../../../state/forms/mailAddressConfirmationForm';
import { useUser } from '../../../state/user';
import { SPONSORSHIP_AGREEMENT_MODAL_STEPS } from '../../../types/sponsorshipAgreementModals';
import { CommonApplicationProfile } from '../../../types/sponsorshipApplication';
import MailingAddressInfoForm
  from '../../esl/profileSections/commonApplication/mailingAddressInfo/MailingAddressInfoForm';

const MailingAddressModal: FunctionComponent = () => {
  const user = useUser();
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const setSponsorshipAgreementActiveModal = useSetRecoilState(sponsorshipAgreementActiveModal);
  const [ fieldErrorHighlightEnabled, setFieldErrorHighlightEnabled ] = useState(false);
  const commonApplicationProfile: CommonApplicationProfile = useMemo(() => user?.contents?.commonApplicationProfile, [user]);

  const addressLine = useRecoilValue(mailAddressConfirmationForm.fields.address_line.valueState);
  const addressDetail = useRecoilValue(mailAddressConfirmationForm.fields.address_detail.valueState);
  const city = useRecoilValue(mailAddressConfirmationForm.fields.city.valueState);
  const state = useRecoilValue(mailAddressConfirmationForm.fields.state.valueState);
  const zip = useRecoilValue(mailAddressConfirmationForm.fields.zip.valueState);

  const addressLineValid = useRecoilValue(mailAddressConfirmationForm.fields.address_line.validState);
  const addressDetailValid = useRecoilValue(mailAddressConfirmationForm.fields.address_detail.validState);
  const cityValid = useRecoilValue(mailAddressConfirmationForm.fields.city.validState);
  const stateValid = useRecoilValue(mailAddressConfirmationForm.fields.state.validState);
  const zipValid = useRecoilValue(mailAddressConfirmationForm.fields.zip.validState);

  const isFormValid = useMemo(() => addressLineValid && addressDetailValid && cityValid && stateValid && zipValid,
    [ addressLineValid, addressDetailValid, cityValid, stateValid, zipValid ]);

  const handleSubmit = useCallback(async () => {
    setFieldErrorHighlightEnabled(true);

    if (!isFormValid) return showErrorAlert('Please fill/fix all the form fields before continuing');

    const mailAddressData = {
      address_line: addressLine.trim(),
      address_detail: addressDetail.trim(),
      city: city.trim(),
      state: state?.abbreviation as string, // Bypassing the null check because the form is invalid if state is null
      zip: zip.trim(),
    };

    try {
      await backend.updateMailingAddressInfo(mailAddressData);
      user.refresh();
      showSuccessAlert('Mailing address info updated');
      setSponsorshipAgreementActiveModal(SPONSORSHIP_AGREEMENT_MODAL_STEPS.HANG_TIGHT);
    } catch (err) {
      showErrorAlert('There was an error updating the mailing address, please try again');
    }
  }, [ isFormValid, showErrorAlert, addressLine, addressDetail, city, state, zip, user, showSuccessAlert, setSponsorshipAgreementActiveModal ]);

  if (!commonApplicationProfile) return null;

  return (
    <Stack p='3rem' spacing='1rem'>
      <Typography variant='h2'>Please Enter Your Permanent Mailing Address</Typography>
      <Typography variant='h5'>We need this to create your final sponsorship agreement.</Typography>
      <MailingAddressInfoForm
        commonApplicationProfile={commonApplicationProfile}
        fieldErrorHighlightEnabled={fieldErrorHighlightEnabled}
        mailingAddressForm={mailAddressConfirmationForm}
      />
      <Button color='primary' onClick={handleSubmit} type='button' variant='contained'>Continue</Button>
    </Stack>
  );
};

export default MailingAddressModal;
