export enum QuestionType {
    BOOLEAN = 'BOOLEAN',
    FREE_TEXT = 'FREE_TEXT',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    RANKING = 'RANKING'
}

export interface QuestionChoice {
    key: string;
    human_readable_value: string;
}

// TODO BE and FE fix, choices should not have choices inside just the choices
export interface QuestionChoices {
    choices: QuestionChoice[];
}

export interface ApplicationQuestion {
    id: string;
    human_readable_value: string;
    question_type: QuestionType;
    required: boolean;
    helper_text: string | null;
    choices: QuestionChoices;
    is_common: boolean;
}
