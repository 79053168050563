import { createElement } from 'react';

import PasswordErrorHelper from '../../../components/input/PasswordErrorHelper';
import {
  EMAIL_REGEXP,
  PASSWORD_REGEXP,
  PHONE_PARTIAL_REGEXP,
  PHONE_REGEXP,
} from '../../../constants/validation';
import { Field } from '../../../lib/recoil/Form';

const authFields = {
  firstName: new Field<string>({
    key: 'authForm/firstName',
    defaultValue: '',
    rules: [
      {
        helperText: 'required',
        pattern: (value) =>
          value !== null && !!value.trim().length,
        severity: 'off',
      },
    ],
  }),
  lastName: new Field<string>({
    key: 'authForm/lastName',
    defaultValue: '',
    rules: [
      {
        helperText: 'required',
        pattern: (value) =>
          value !== null && !!value.trim().length,
        severity: 'off',
      },
    ],
  }),
  emailAddress: new Field<string>({
    key: 'authForm/emailAddress',
    defaultValue: '',
    rules: [
      {
        helperText: 'Invalid email address',
        pattern: (value) =>
          EMAIL_REGEXP.test(value),
        severity: 'off',
      },
    ],
    persists: false,
  }),
  mobilePhoneNumber: new Field<string>({
    key: 'authForm/mobilePhoneNumber',
    defaultValue: '',
    checkSafety: (rawValue) =>
      PHONE_PARTIAL_REGEXP.test(rawValue),
    rules: [
      {
        helperText: 'Please enter a valid phone number',
        pattern: (value) =>
          PHONE_REGEXP.test(value),
        severity: 'off',
      },
    ],
    persists: false,
  }),
  address: new Field<string>({
    key: 'authForm/address',
    defaultValue: '',
    rules: [
      {
        helperText: 'required',
        pattern: (value) =>
          value !== null && !!value.trim().length,
        severity: 'off',
      },
    ],
  }),
  // Specific login password field with soft validation following SSP-1379
  loginPassword: new Field<string>({
    key: 'authForm/loginPassword',
    defaultValue: '',
    rules: [
      {
        helperText: 'Password is required',
        pattern: (value) =>
          value !== null && !!value.trim().length,
        severity: 'off',
      },
    ],
    persists: false,
  }),
  password: new Field<string>({
    key: 'authForm/password',
    defaultValue: '',
    rules: [
      {
        helperText: createElement(PasswordErrorHelper),
        pattern: (value) =>
          PASSWORD_REGEXP.test(value),
        severity: 'off',
      },
    ],
    persists: false,
  }),
  rememberMeFlag: new Field<boolean>({
    key: 'authForm/rememberMeFlag',
    defaultValue: false,
    rules: [],
  }),
  mobileConsent: new Field<boolean>({
    key: 'authForm/mobileConsent',
    defaultValue: false,
    rules: [
      {
        helperText: 'required',
        pattern: (value) =>
          value,
        severity: 'error',
      },
    ],
  }),
  termsConsent: new Field<boolean>({
    key: 'authForm/termsConsent',
    defaultValue: false,
    rules: [
      {
        helperText: 'required',
        pattern: (value) =>
          value,
        severity: 'error',
      },
    ],
  }),
};

export default authFields;
