import { backendAuthenticatedAxios } from '..';
import jwt, { Tokens } from '../auth/jwt';

/**
 * Authenticates the user with an OAuth2 provider and code
 */
const signIn = async (email: string, password: string): Promise<void> => {
  return backendAuthenticatedAxios.post<Partial<Tokens>>(
    '/account/sign-in',
    { email, password },
  ).then(
    (res) => {
      if (res.status === 200) {
        const { access, refresh } = res.data;

        jwt.save(access, refresh);
      } else {
        throw new Error('Failed to sign in');
      }
    },
  );
};

export default signIn;
