import PartnerReferral from '../../../types/responseSchema/partnerReferral';
import env from '../../env';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getDownstreamReferrals = async (fundingAppId: number): Promise<AxiosResponse<PartnerReferral[]>> => {
  // TODO: Update in SSP-433
  const partnersReferralEndpoint = env.feature.USE_QUOTE_REQUEST
    ? 'quote/refer?id'
    : 'partner/application-referral?application_id';

  const response = await backendAuthenticatedAxios.get<PartnerReferral[]>(
    `${env.var.API_URL_EXTERNAL}/${partnersReferralEndpoint}=${fundingAppId}`,
  );
  console.log('Partner Referrals response:', response);

  return response;
};

export default getDownstreamReferrals;
