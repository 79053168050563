import { backendAuthenticatedAxios } from '..';
import jwt, { Tokens } from '../auth/jwt';
import { SocialAuthProvider } from '../types';

/**
 * Authenticates the user with an OAuth2 provider and code
 *  and reloads the page if successful
 */
const socialSignOn = async (provider: SocialAuthProvider, code: string): Promise<void> => {
  return backendAuthenticatedAxios.post<Partial<Tokens>>(
    `/account/link-social/${provider}/token`,
    { code: code },
  ).then(
    (res) => {
      console.log('socialSignOn:', res);

      if (res.status === 200 || res.status === 201) {
        const { access, refresh } = res.data;
        console.log('Saving JWT....');
        jwt.save(access, refresh);
      } else {
        throw new Error('Failed to sign in');
      }
    },
  );
};

export default socialSignOn;
