import { FunctionComponent } from 'react';
import Image from 'next/image';

import { CustomLink } from '../CustomLink';

export interface LogoProps {
  logoImage: StaticImageData;
}

const Logo: FunctionComponent<LogoProps> = ({
  logoImage,
}) => (
  <CustomLink href='/'>
    <>
      <Image alt='Stride' src={logoImage} />
    </>
  </CustomLink>
);

export default Logo;
