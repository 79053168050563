import { Region } from '../../../types/location';
import { PaginationState } from '../../../types/pagination';
import { backendAuthenticatedAxios } from '..';
import { getPaginationQueryParams } from '../pagination';
import { PaginatedResponse } from '../types';

import { AxiosResponse } from 'axios';

type GetOpportunityLocationsParams = {
  opportunityId: string;
  searchTerms?: string[];
  pagination?: PaginationState;
};

export type ListOpportunityLocationsResponse = PaginatedResponse<Region>;

const getOpportunityLocations = async ({
  opportunityId,
  searchTerms = [],
  pagination,
}: GetOpportunityLocationsParams): Promise<AxiosResponse<ListOpportunityLocationsResponse>> => {
  /**
   * Backend request to get Sponsorship Opportunity locations using a list of search terms.
   */

  // Relative URL
  const relativeUrl = 'locations';

  // Query parameters
  let paginationOptions = {};

  if (pagination) {
    paginationOptions = getPaginationQueryParams(pagination);
  }

  const queryParams = new URLSearchParams({
    ...(paginationOptions || {}),
    search: searchTerms.join(','),
    opportunityId,
  });

  return backendAuthenticatedAxios.get(`${relativeUrl}?${queryParams.toString()}`);
};

export default getOpportunityLocations;
