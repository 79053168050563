import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const createSponsorshipProfile = async (requestData: string): Promise<AxiosResponse<void>> => {
  return backendAuthenticatedAxios.post<void>(
    '/quote/submit', requestData,
  );
};

export default createSponsorshipProfile;
