import { useMemo } from 'react';
import { Loadable, useRecoilCallback, useRecoilValueLoadable } from 'recoil';

import { userRoles } from '../../constants/userRoles';

import { User } from './types';
import userState from './userState';

import LogRocket from 'logrocket';
import posthog from 'posthog-js';

type UseUser = () => Loadable<User | null> & {
  /** Used to identify a user on LogRocket */
  identify: () => void;
  /** Used to identify a user on PostHog */
  identifyPosthog: () => void;
  /** Indicates whether the user is currently loaded */
  isLoaded: boolean;
  /** Indicates whether the user is currently loaded and signed in */
  isSignedIn: boolean;
  /** Clears the `userState` cache to force update via  */
  refresh: () => void;
};

/**
 * Provides readonly access to the user's identity information
 *  as well as methods for signing the user in and out.
 *
 * @see userState for a description of how the state is populated
 *
 * @todo Subscribe `useUser` to changes to the refresh token cookie
 */
const useUser: UseUser = () => {
  const userLoadable = useRecoilValueLoadable(userState);

  const refresh = useRecoilCallback(({ refresh }) => () => {
    console.log('Refreshing cache for selector \'userState\'');
    refresh(userState);
  }, []);

  const isLoaded = useMemo(() => userLoadable.state === 'hasValue' && !!userLoadable.contents, [userLoadable]);

  const isSignedIn = isLoaded && userLoadable.contents.userRole !== userRoles.anonymous;

  // Update this function to add more tracking info to be sent to LogRocket
  const identify = () => {
    LogRocket.identify(userLoadable.contents.email, {
      name: `${userLoadable.contents.firstName} ${userLoadable.contents.lastName}`,
      email: String(userLoadable.contents.email),
    });
  };

  // Update this function to add more tracking info to be sent to PostHog
  const identifyPosthog = () => {
    posthog.identify(
      userLoadable.contents.email, {
        name: `${userLoadable.contents.firstName} ${userLoadable.contents.lastName}`,
        email: String(userLoadable.contents.email),
      });
  };

  return {
    isLoaded,
    isSignedIn,
    refresh,
    identify,
    identifyPosthog,
    ...userLoadable,
  };
};

export default useUser;
