import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import backend from '../../../lib/backend';
import documentsSubmitForm from '../../../state/forms/eslForms/documentsSubmitForm';
import { ValidationError } from '../../../types/errors/validation';
import {
  SponsorshipApplicationFiles,
  StudentSponsorshipApplication,
} from '../../../types/sponsorshipApplication';

/**
 * Interface for the return type of the useDocumentsForm hook
 */
interface UseDocumentsForm {
  submitForm: () => Promise<SponsorshipApplicationFiles>;
}

/**
 * Custom hook to manage the state and submission logic for the documents form.
 *
 * @param sponsorshipApplication - The sponsorship application data
 * @param requiredDocuments - Array of required document types
 * @returns An object containing the submitForm handler function
 */
const useDocumentsForm = (
  sponsorshipApplication: StudentSponsorshipApplication | null,
  requiredDocuments: string[] | null,
): UseDocumentsForm => {
  const documentFiles = useRecoilValue(documentsSubmitForm.fields.documentFiles.valueState);

  const validForm = useMemo(() => {
    if (!requiredDocuments || requiredDocuments.length === 0) {
      return true;
    }

    return requiredDocuments.every((doc) => doc in documentFiles && documentFiles[doc] !== null);
  }, [ documentFiles, requiredDocuments ]);

  const submitForm = useCallback(async () => {
    if (!validForm) {
      throw new ValidationError('Please upload all required files before submitting.');
    }

    const formData = new FormData();

    Object.keys(documentFiles).forEach((key) => {
      const file = documentFiles[key];

      if (file instanceof File) {
        formData.append(key, file);
      }
    });

    const { data: sponsorshipApplicationFiles } = await backend.submitFiles(sponsorshipApplication?.id as string, formData);

    return sponsorshipApplicationFiles;
  }, [ validForm, documentFiles, sponsorshipApplication ]);

  return { submitForm };
};

export default useDocumentsForm;
