import { backendAnonymousAxios, backendAuthenticatedAxios } from '..';
import { BackendApiConfig } from '../types';

import jwt, { Tokens } from './jwt';

import { AxiosError } from 'axios';

/**
 * Intercepts errors with status `401` and code `token_not_valid`
 *
 * Attempts to repair JWT `access`/`refresh` tokens if they are invalid
 *  and then retries the request (recursive)
 */
const interceptAuthError = async (error: AxiosError): Promise<unknown> => {
  if (
    error.response?.status === 401
    && error.response.data.code === 'token_not_valid'
  ) {
    console.warn('JWT: Invalid access token', error.request.responseURL);
    const request = error.config as BackendApiConfig;

    // Retry failed request if have not already
    if (!request._retry) {
      backendAnonymousAxios.post<{ access?: string }>('/account/refresh', {
        refresh: jwt.refresh,
      }).then(({ data: { access }}) => {
        jwt.save(access);
      }).catch(() => {
        console.warn('JWT: Invalid refresh token. Getting anonymous tokens');

        return backendAnonymousAxios.get<Partial<Tokens>>('/account/auth')
          .then(
            ({ data: { access, refresh }}) =>
              jwt.save(access, refresh),
          ).catch(() => {
            console.error('JWT: Failed to repair tokens');

            jwt.clear();
          });
      }).finally(() => {
        //TODO: SSP-2582: Avoid throwing error in retry logic
        request.headers['Authorization'] = `Bearer ${jwt.access}`;
        console.log('JWT: Retrying request with new access token');
        request._retry = true;

        return backendAuthenticatedAxios(request);
      });
    } else
      throw new Error('JWT: Unhandled JWT error').stack;
  } else if (
    error.response?.status === 401
    && error.response?.data.code === 'user_not_found'
  ) {
    // TODO -> Should be handled by BE once /account/me returns 200 for anonymous users
    // Currently it's returning a 401, but its a valid flow so no error should be thrown
    console.log('Error in auth interception logic. Doing nothing. backendAnonymousAxios will be used');

    return;
  }

  // In other "valid" error cases, throw error
  throw error;
};

export default interceptAuthError;
