import { FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import { Dialog } from '@mui/material';

import env from '../../../lib/env';
import spinwheelActiveModal from '../../../state/atoms/spinwheelActiveModal';
import { REPAYMENT_MODAL_STEPS } from '../../../types/spinwheelModals';

import SelectLoanServicerAccount from './SelectLoanServicerAccount';
import SpinwheelDirectStudentLoanConnectModal from './SpinwheelDirectStudentLoanConnectModal';
import SpinwheelIdentityConnectModal from './SpinwheelIdentityConnectModal';
import SpinwheelKBAModal from './SpinwheelKBAModal';
import SpinwheelListLoansModal from './SpinwheelListLoansModal';
import SpinwheelListTransactionsModal from './SpinwheelListTransactionsModal';
import SpinwheelProvider from './SpinwheelProvider';
import UserAgreementModal from './UserAgreementModal';

/**
 * SpinwheelModalContainer is a React component that acts as a container for various Spinwheel modals.
 * It uses Recoil to manage the state of the active modal and conditionally renders the appropriate Spinwheel
 * modal component based on the current active modal's state. This container ensures that the correct modal
 * is displayed to the user based on their interaction with the Spinwheel functionalities.
 *
 * All necessary Spinwheel modal components should be included in this container.
 * It encapsulates the logic for rendering specific modals such as the SpinwheelDirectStudentLoanConnectModal,
 * SpinwheelListLoansModal, and SpinwheelListTransactionsModal, based on the active modal state.
 *
 * @component
 */
const SpinwheelModalContainer: FunctionComponent = () => {
  const [ activeModal, setActiveModal ] = useRecoilState(spinwheelActiveModal);

  // Conditional rendering of Spinwheel modals occurs: neither the provider nor the modals are rendered if no modal is currently active
  if (!activeModal) return null;

  return (
    <SpinwheelProvider>
      <Dialog onClose={() => setActiveModal(null)} open={true}>
        {
          activeModal === REPAYMENT_MODAL_STEPS.USER_AGREEMENT
          && env.feature.SPINWHEEL_V2 && <UserAgreementModal />
        }
        {
          activeModal === REPAYMENT_MODAL_STEPS.LOAN_SERVICES_LOGIN && (
            env.feature.SPINWHEEL_V2
              ? <SpinwheelIdentityConnectModal />
              : <SpinwheelDirectStudentLoanConnectModal />
          )
        }
        {activeModal === REPAYMENT_MODAL_STEPS.KBA_VERIFICATION && <SpinwheelKBAModal />}
        {activeModal === REPAYMENT_MODAL_STEPS.LOAN_LIST && <SpinwheelListLoansModal />}
        {activeModal === REPAYMENT_MODAL_STEPS.TRANSACTION_HISTORY && <SpinwheelListTransactionsModal />}
        {activeModal === REPAYMENT_MODAL_STEPS.SELECT_LOAN_SERVICER_ACCOUNT && <SelectLoanServicerAccount />}
      </Dialog>
    </SpinwheelProvider>
  );
};

export default SpinwheelModalContainer;
