import React, { FunctionComponent } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import useConfirmation from '../../hooks/useConfirmation';

const ConfirmationDialog: FunctionComponent = () => {
  const { confirmationStatus, handleDialogClose, handleDialogConfirm } = useConfirmation();

  return (
    <Dialog
      onClose={handleDialogClose}
      open={confirmationStatus?.dialogOpen ?? false}
    >
      <Box id='confirmation-dialog' sx={{ px: 3, py: 2 }}>
        <DialogTitle>{confirmationStatus?.messages?.title}</DialogTitle>
        <DialogContent>
          {confirmationStatus?.messages?.description}
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button autoFocus id='confirmation-dialog-confirm-action' onClick={handleDialogConfirm} size='small' sx={{ m: 1 }} variant='contained'>
              {confirmationStatus?.messages?.confirm}
            </Button>
            <Button id='confirmation-dialog-cancel-action' onClick={handleDialogClose} size='small' sx={{ m: 1 }} variant='outlined'>
              {confirmationStatus?.messages?.cancel}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
