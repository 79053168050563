import { PartnerType } from '../../../types/partner';
import { PartnerListData } from '../../../types/responseSchema/partnerData';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Method to retrieve partner list
 *
 * @returns partner list, used to power the FE
 */
const getPartnerList = async (partnerType: PartnerType):
Promise<AxiosResponse<PartnerListData[] | null>> => {
  const queryParams = new URLSearchParams({
    partner_type: partnerType,
  });

  return await backendAuthenticatedAxios.get(`partner/get-partners?${queryParams.toString()}`);
};

export default getPartnerList;
