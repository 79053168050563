import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

interface GenerateSponsorshipAgreementResponse {
  sign_url: string;
}

const generateSponsorshipAgreement = async (sponsorshipApplicationId: string): Promise<AxiosResponse<GenerateSponsorshipAgreementResponse>> => {
  return backendAuthenticatedAxios.post<GenerateSponsorshipAgreementResponse>(
    `/esl-student/${sponsorshipApplicationId}/sponsorship-agreement`,
  );
};

export default generateSponsorshipAgreement;
