/**
 * This file contains constants for date and time formats used throughout the application.
 *
 * @fileoverview
 */

/**
 * The default date format used in the application.
 */
export const DEFAULT_APP_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * The default time format used in the application.
 */
export const DEFAULT_APP_TIME_FORMAT = 'h:mm aa';

/**
 * The date format used for input fields.
 */
export const INPUT_DATE_FORMAT = 'M/d/yyyy';

/**
 * The standard date format used for backend communication.
 */
export const STANDARD_DATE_FORMAT = 'yyyy-MM-dd';
