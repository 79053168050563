import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

type PaginationOptions = {
  page_size: string;
  page: string;
};

type OrderingOptions = {
  ordering: string;
};

type FilteringOptions = Record<string, string>;

export const getPaginationQueryParams = (pagination: MRT_PaginationState): PaginationOptions => ({
  'page_size': String(pagination.pageSize),
  'page': String(pagination.pageIndex + 1),
});

export const getOrderingQueryParams = (sorting: MRT_SortingState | undefined): OrderingOptions | Record<string, never> => {
  if (!sorting) return {};

  const orderingParam = sorting.reduce<string[]>((acc, sortingTerm) => {
    acc.push((sortingTerm.desc ? '-' : '') + sortingTerm.id);

    return acc;
  }, []);

  return { 'ordering': orderingParam.join(',') };
};

export const getFiltersQueryParams = (columnFilters: MRT_ColumnFiltersState | undefined): FilteringOptions | Record<string, never> => {
  if (!columnFilters) return {};

  return columnFilters.reduce<FilteringOptions>((acc, filterTerm) => {
    acc[filterTerm.id] = String(filterTerm.value);

    return acc;
  }, {});
};
